/* General Layout */
.App {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

/* Left side for creating notes */
.left-side {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ccc;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
}

.left-side h1 {
  margin-bottom: 20px;
}

.note-container input,
.note-container textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.note-container textarea {
  height: 100px;
  resize: none;
}

.note-container button {
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Right side for viewing and searching notes */
.right-side {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.right-side h1 {
  margin-bottom: 20px;
}

.search-container {
  margin-bottom: 20px;
}

.search-container input {
  width: calc(100% - 20px);
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.search-container .dropdown-toggle {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Styling for the NoteList and individual notes */
.notes-list {
  list-style: none;
  padding: 0;
}

.notes-list li {
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.notes-list li h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.notes-list li p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 14px;
}

.notes-list li button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
