.note-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.note-header h3 {
    margin: 0;
}

.delete-button {
    background-color: #ff5f5f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #ff3c3c;
}

.note-body p {
    margin: 5px 0;
    line-height: 1.4;
}
